import CallToAction from "@/components/Button";
import { SVGArrowRight } from "@/lib/svg";
import { forwardRef } from "react";
import styled from "styled-components";
import { ImageWrapper as _ImageWrapper } from "@/lib/styles/theme";
import { H4, P } from "@/lib/styles";

export const Container = styled.div<{ $position: string }>`
  grid-column: 1 / -1;

  ${({ theme }) => theme.mediaQueries.tablet} {
    display: flex;
    align-self: ${({ $position }) =>  $position === "left" ? "flex-start" : "flex-end"};
    flex-direction: column;
  }
`

export const ContainerImage = styled(_ImageWrapper)<{ $hasLoaded: any, $textPresent: boolean, $size: string, $isVertical: boolean }>`
  margin-bottom: 1.5rem;
  opacity: 0;
  transform: translateY(100px);
  width: 100%;
  padding-bottom: ${({ $textPresent }) =>  $textPresent ? "56%" : "75%"};

  ${({ theme }) => theme.mediaQueries.tablet} {
    width: 37.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding-bottom: ${({ $textPresent, $isVertical }) => $isVertical ? "133%" : ($textPresent ? "56%" : "75%")};
    width: 24.5625rem;
    margin-bottom: 0;
  }
  ${({ theme }) => theme.mediaQueries.desktop} {
    width: ${({ $size }) => $size === "small" ? "28.325rem" : "35.375rem"};
  }
`;

export const Content = styled.div`
  width: 14.875rem;
  padding: 0 1.5rem;
  display: flex;
  margin: 0;
  justify-content: center;
  align-items: baseline;
  flex-direction: column;

  ${({ theme }) => theme.mediaQueries.tablet} {
    margin: 0 1.5rem 0;
    width: 34.5rem;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    margin: 0;
    width: 21.5625rem;
  }

  ${({ theme }) => theme.mediaQueries.desktop} {
    width: 32.375rem;
  }
`;

export const Excerpt = styled(P)`
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0;
  padding: 0.5rem 0;
  margin-bottom: 1rem;

  .lines {
    opacity: 0;
    transform: translateY(20px);
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    padding: 0.5rem 0;
  }
`;

export const Heading = styled(H4)`
  opacity: 0;
  padding: 1.5rem 0 1rem 0;
  color: ${({ theme }) => theme.colors.red};

  .lines {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const _Button = forwardRef<
  HTMLAnchorElement,
  { children: string; href?: string; target?: string }
>((props, ref) => (
  <CallToAction
    ref={ref}
    as="a"
    variant="primaryUnboxed"
    icon={<SVGArrowRight />}
    {...props}
  />
));

_Button.displayName = "_Button";

export const Button = styled(_Button)`
  opacity: 0;
  transform: translateY(20px);

  &:not(:last-child) {
    margin: 0 1.5rem;
  }
`;

export const ButtonsContainer = styled.div`
  align-self: start;
`;
