import AppLink, { AppLinkProps } from "@/components/AppLink";
import { Button } from "./styles";

export type InternalLinkProps = AppLinkProps;

const InternalLink: React.FC<InternalLinkProps> = (props) => {
  const { title, page, params } = props;
  if (!title) return null;

  return (
    <AppLink page={page} params={params}>
      <Button>{title}</Button>
    </AppLink>
  );
};

export default InternalLink;
