import gsap, { SplitText } from "@/lib/gsap";
import React, { useEffect, useRef } from "react";
import ExternalLink from "./ExternalLink";
import InternalLink from "./InternalLink";
import IllustrationBlock from "./IllustrationBlock";
import {
  ButtonsContainer,
  Container,
  Content,
  Excerpt,
  Heading,
} from "./styles";
import _SplitText from "gsap/SplitText";
import { debounce } from "lodash";

type Props = {
  excerpt?: any;
  heading?: any;
  tagline?: any;
  image?: any;
  buttons?: any[];
};

const TextWithIllustration: React.FC<Props> = (props) => {
  const { image, excerpt, heading, buttons } = props;
  const imagePosition = image?.position || "left";

  const textPresent = (excerpt || heading);

  const containerRef = useRef<HTMLDivElement>(null);
  const imageWrapperRef = useRef<HTMLDivElement>(null);
  const headingRef = useRef<HTMLHeadingElement>(null);
  const excerptRef = useRef<HTMLParagraphElement>(null);
  const buttonsContainerRef = useRef<HTMLDivElement>(null);

  const headingTextRef = useRef<_SplitText | null>(null);
  const excerptTextRef = useRef<_SplitText | null>(null);

  useEffect(() => {
    const revertText = debounce(() => {
      if (headingTextRef.current && excerptTextRef.current) {
        headingTextRef.current?.revert()
        excerptTextRef.current?.revert()
      }
    }, 30);

    // Create closure around mutable refs
    const container = containerRef.current;
    const imageWrapper = imageWrapperRef.current;
    const heading = headingRef.current;
    const excerpt = excerptRef.current;
    const buttonsContainer = buttonsContainerRef.current;

    const timeline = gsap
      .timeline({
        defaults: {
          duration: 0.3,
        },
        scrollTrigger: {
          trigger: container,
          toggleActions: "play pause resume reverse",
          start: "top 75%",
        },
      })
      .to(imageWrapper, {
        opacity: 1.0,
        translateY: 0,
      })

    if (heading && excerpt) {
      headingTextRef.current = new SplitText(heading, { type: "lines", linesClass: "lines" });
      excerptTextRef.current = new SplitText(excerpt, { type: "lines", linesClass: "lines" });
      const headingText = headingTextRef.current;
      const excerptText = excerptTextRef.current;
      window.addEventListener("resize", revertText);
      gsap.set([heading, excerpt], { opacity: 1.0 });

      timeline.to(
        [headingText.lines, excerptText.lines],
        {
          opacity: 1.0,
          translateY: 0,
          stagger: { amount: 0.3 },
        },
        "<25%"
      );
    } 

    if (buttonsContainer) {
      timeline.to(
        [buttonsContainer.children],
        {
          opacity: 1.0,
          translateY: 0,
          stagger: { amount: 0.3 },
        },
        "<25%"
      );
    } 

    return function cleanup() {
      window.removeEventListener("resize", revertText);
      timeline.kill();
    };
  }, []);

  return (
    <Container ref={containerRef} $imagePosition={imagePosition} $textPresent={textPresent}>
      <IllustrationBlock ref={imageWrapperRef} image={image} />
      <Content>
        {heading && <Heading ref={headingRef}>{heading}</Heading>}
        {excerpt && (
          <Excerpt
            ref={excerptRef}
            dangerouslySetInnerHTML={{
              __html: excerpt.replace(/\n/g, "<br />"),
            }}
          ></Excerpt>
        )}
        {buttons && 
          <ButtonsContainer ref={buttonsContainerRef}>
            {buttons?.map((button: any) => {
              if (!button?._key) return null;
              switch (button._type) {
                case "internalLink":
                  return <InternalLink key={button._key} {...button} />;
                case "externalLink":
                  return <ExternalLink key={button._key} {...button} />;
                default:
                  return null;
              }
            })}
          </ButtonsContainer>
        }
      </Content>
    </Container>
  );
};

export default TextWithIllustration;
