import { Button } from "./styles";

type Props = {
  title?: any;
  url?: any;
  blank: boolean;
};

const ExternalLink: React.FC<Props> = (props) => {
  const { title, url, blank } = props;

  if (typeof url !== "string") return null;
  if (typeof title !== "string") return null;

  return <Button href={url} target={`${blank ? "_blank" : ""}`}>{title}</Button>;
};

export default ExternalLink;
