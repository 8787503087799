import CallToAction from "@/components/Button";
import { SVGArrowRight } from "@/lib/svg";
import { forwardRef } from "react";
import styled from "styled-components";
import { ImageWrapper as _ImageWrapper } from "@/lib/styles/theme";
import { H2, P } from "@/lib/styles";

export const Container = styled.div<{ $imagePosition?: string, $textPresent?: boolean }>`
  margin-bottom: 0;
  flex-direction: column;
  flex-wrap: wrap;

  display: grid;
  grid-template-columns: repeat(2,1fr);

  ${({ theme }) => theme.mediaQueries.tablet} {
    display: flex;
  }

  ${({ theme }) => theme.mediaQueries.laptop} {
    flex-direction: ${({ $imagePosition }) => $imagePosition?.includes("right") ? "row-reverse" : "row"};
    flex-wrap: nowrap;
    justify-content: ${({ $textPresent, $imagePosition }) => $textPresent ? "space-between" : ($imagePosition?.includes("center") ? "center" : "space-between")};
    &:not(:last-child) {
      margin-bottom: 0;
    }
  }
`;

export const Content = styled.div`
  grid-column: 1 / -1;

  ${({ theme }) => theme.mediaQueries.laptop} {
    width: calc(50% - 1.5rem);
    margin-top: 0;
    display: flex;
    justify-content: center;
    align-items: baseline;
    flex-direction: column;

    padding: 0 0.75rem;
  }
`;

export const Excerpt = styled(P)`
  background-color: ${({ theme }) => theme.colors.white};
  opacity: 0;
  padding: 0;
  margin-bottom: 1.5rem;

  .lines {
    opacity: 0;
    transform: translateY(20px);
  }
`;

export const Heading = styled(H2)`
  margin-bottom: 1.5rem;
  opacity: 0;
  padding: 0;

  .lines {
    opacity: 0;
    transform: translateY(20px);
  }
`;

const _Button = forwardRef<
  HTMLAnchorElement,
  { children: string; href?: string; target?: string }
>((props, ref) => (
  <CallToAction
    ref={ref}
    as="a"
    variant="primaryUnboxed"
    icon={<SVGArrowRight />}
    {...props}
  />
));

_Button.displayName = "_Button";

export const Button = styled(_Button)`
  width: fit-content;
  opacity: 0;
  transform: translateY(20px);

  &:not(:last-child) {
    margin-bottom: 1rem; // This covers a design exception
  }
`;

export const ButtonsContainer = styled.div`
  align-self: start;
`;
