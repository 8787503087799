import gsap, { SplitText } from "@/lib/gsap";
import { imageLoader } from "@/lib/sanity/client";
import Image from "next/image";
import ExternalLink from "./ExternalLink";
import InternalLink from "./InternalLink";
import React, { forwardRef, useState, useRef, useEffect } from "react";
import {
  Container,
  ContainerImage,
  ButtonsContainer,
  Content,
  Excerpt,
  Heading,
} from "./styles";
import _SplitText from "gsap/SplitText";
import { debounce } from "lodash";

type Props = {
  image: {
    heading: string | null;
    excerpt: string | null;
    position: string;
    buttons: any;
    size: string;
    format: string | null;
    asset: any | null;
    alt: string | null;
  };
};

const Illustration = forwardRef<HTMLDivElement, Props>((props, ref) => {
  const { image } = props;
  const {
    heading,
    excerpt,
    position = "left",
    buttons,
    size = "big",
    format,
  } = image;
  const [loaded, setLoaded] = useState<boolean>(false);

  const imageIsVertical = format === "portrait";
  const loadTextBlock = heading !== null && excerpt !== null;
  const headingRef = useRef<HTMLHeadingElement>(null);
  const excerptRef = useRef<HTMLParagraphElement>(null);
  const buttonsContainerRef = useRef<HTMLDivElement>(null);

  const headingTextRef = useRef<_SplitText | null>(null);
  const excerptTextRef = useRef<_SplitText | null>(null);

  useEffect(() => {
    // Create closure around mutable refs
    if (!loadTextBlock) return;
    const heading = headingRef.current;
    const excerpt = excerptRef.current;
    const buttonsContainer = buttonsContainerRef.current;
    if (!buttonsContainer) return;
    headingTextRef.current = new SplitText(heading, {
      type: "lines",
      linesClass: "lines",
    });
    excerptTextRef.current = new SplitText(excerpt, {
      type: "lines",
      linesClass: "lines",
    });
    const headingText = headingTextRef.current;
    const excerptText = excerptTextRef.current;
    const revertText = debounce(() => {
      if (headingText && excerptText) {
        headingText?.revert();
        excerptText?.revert();
      }
    }, 30);

    window.addEventListener("resize", revertText);
    gsap.set([heading, excerpt], { opacity: 1.0 });

    const timeline = gsap
      .timeline({
        defaults: {
          duration: 0.3,
        },
        scrollTrigger: {
          trigger: heading,
          toggleActions: "play pause resume reverse",
          // scrub: 0.5,
          start: "top 90%",
        },
      })
      .to(
        [headingText.lines, excerptText.lines, buttonsContainer.children],
        {
          opacity: 1.0,
          translateY: 0,
          stagger: { amount: 0.3 },
        },
        "<25%"
      );

    return function cleanup() {
      window.removeEventListener("resize", revertText);
      timeline.kill();
    };
  }, [loadTextBlock]);

  return (
    <Container $position={position}>
      <ContainerImage
        $hasLoaded={loaded}
        $textPresent={loadTextBlock}
        $isVertical={imageIsVertical}
        $size={size}
        ref={ref}
      >
        {image?.asset?.url && (
          <Image
            src={image.asset.url}
            layout="fill"
            objectFit="cover"
            alt={image?.alt || ""}
            loader={imageLoader}
            onLoadingComplete={() => setLoaded(true)}
            sizes="100vw"
            priority
          />
        )}
      </ContainerImage>
      {loadTextBlock && (
        <Content>
          <Heading ref={headingRef}>{heading}</Heading>
          <Excerpt ref={excerptRef}>{excerpt}</Excerpt>
          {buttons && (
            <ButtonsContainer ref={buttonsContainerRef}>
              {buttons?.map((button: any) => {
                if (!button?._key) return null;
                switch (button._type) {
                  case "internalLink":
                    return <InternalLink key={button._key} {...button} />;
                  case "externalLink":
                    return <ExternalLink key={button._key} {...button} />;
                  default:
                    return null;
                }
              })}
            </ButtonsContainer>
          )}
        </Content>
      )}
    </Container>
  );
});

Illustration.displayName = "Illustration";

export default Illustration;
